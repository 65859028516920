import { Controller } from 'stimulus';
import LocalProfileStorage from '../../lib/LocalProfileStorage';

export default class extends Controller {
  static targets = ['topicGroup', 'imageContainer'];

  connect() {
    this.applyBackgroundColors();
    this.setActiveItems();
  }

  applyBackgroundColors() {
    this.imageContainerTargets.forEach(el => {
      const color = el.dataset.color;
      if (color) el.style.backgroundColor = color;
    });
  }

  setActiveItems() {
    const activeItems =
      LocalProfileStorage.getItem('active_topic_group_accordion') || [];

    this.topicGroupTargets.forEach(element => {
      if (activeItems.includes(element.dataset.topicGroupId))
        element.classList.add('is-active');
    });
  }

  toggleActive(el) {
    const cardElement = el.target.closest(
      '.practice-topics-topic-group-list__topic-group'
    );
    const topicGroupId = cardElement.dataset.topicGroupId;

    const isActive = cardElement.classList.toggle('is-active');

    // Save the active items in local storage
    let activeItems =
      LocalProfileStorage.getItem('active_topic_group_accordion') || [];

    if (isActive) {
      activeItems.push(topicGroupId);
      LocalProfileStorage.setItem('active_topic_group_accordion', activeItems);
    } else {
      activeItems = activeItems.filter(id => id !== topicGroupId);
      if (activeItems.length)
        LocalProfileStorage.setItem(
          'active_topic_group_accordion',
          activeItems
        );
      else LocalProfileStorage.removeItem('active_topic_group_accordion');
    }
  }
}
