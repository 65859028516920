import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['gridButton', 'listButton'];

  goBack = () => {
    const referrerIsSameOrigin =
      document.referrer.indexOf(location.origin) === 0;
    const useCaseSelectorUrl = window.location.pathname.replace(/\/[^/]*$/, '');

    if (referrerIsSameOrigin) window.history.back();
    else Turbolinks.visit(useCaseSelectorUrl);
  };

  toggleViewMode(el) {
    const targetButton = el.target.closest('button');

    const isListButtonClicked = targetButton === this.listButtonTarget;

    this.listButtonTarget.classList.toggle('is-active', isListButtonClicked);
    this.gridButtonTarget.classList.toggle('is-active', !isListButtonClicked);

    // New logic for view modes can be added here.
  }
}
